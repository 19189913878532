import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import popup2 from "../img/popup.jpg";
import popup_mobile2 from "../img/popup_mobile.jpg";
import popup from "../img/popup2.png";
import popup_mobile from "../img/popup_mobile2.png";

const Popup = () => {
  const [open, setOpen] = useState(true);

  return (
    // <div className={`${open ? "fixed" : "hidden" } w-screen h-screen overflow-x-hidden bg-black/70 z-[1000]`}>
    // <div className='absolute w-5/6 max-w-[480px] h-auto md:w-1/2 lg:w-[480px] bg-transparent top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 '>
    //    <IoCloseSharp size={40} className="absolute -right-5 lg:-right-14 -top-12 text-white cursor-pointer" onClick={() => setOpen(false)} />
    //    <img src="assets/images/popup.png" alt="popup_image" className='w-full h-full object-contain' />
    //    <a href='https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us' rel="noreferrer" target="_blank" className='absolute left-5 sm:left-8 lg:left-10 bottom-6 lg:bottom-10 tracking-widest text-sm lg:text-lg cursor-pointer font-primary lowercase bg-black text-white py-1 md:py-1.5 px-1.5 md:px-3 whitespace-nowrap transition-all hover:bg-th-red hover:text-white'>RESERVE NOW</a>
    // </div>
    // </div>
    <>
      <div
        className={`${
          open ? "fixed" : "md:hidden"
        } w-screen h-screen overflow-x-hidden bg-black/70 z-[1000] hidden md:inline-block`}
      >
        <div className="absolute w-5/6 max-w-[480px] h-auto md:max-w-[1200px] bg-transparent top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#EAE4DA] flex">
          <IoCloseSharp
            size={40}
            className="absolute -right-5 lg:-right-14 -top-12 text-white cursor-pointer"
            onClick={() => setOpen(false)}
          />
          {/* <div className="relative hidden md:inline-block w-1/2 px-4">
            <img
              src={popup}
              alt="popup_image"
              className="h-full object-contain"
            />
            <a
              href="/groupbookings"
              className="absolute left-[20%] bottom-3 tracking-widest text-sm cursor-pointer font-primary lowercase bg-black text-white py-1 px-1.5 whitespace-nowrap transition-all hover:bg-th-red hover:text-white
            md:py-1.5 md:px-3 md:left-[32%] md:bottom-2
            lg:left-[37%] lg:bottom-6 lg:text-lg"
            >
              INQUIRE NOW
            </a>
          </div> */}
          <div className="relative hidden md:inline-block w-1/2 px-4 m-auto">
            <img
              src={popup}
              alt="popup_image"
              className="h-full object-contain"
            />
            <a
              href="assets/menus/the_farmhouse_valentine_day.pdf"
              className="absolute left-[20%] bottom-3 tracking-widest text-sm cursor-pointer font-primary lowercase bg-black text-white py-1 px-1.5 whitespace-nowrap transition-all hover:bg-th-red hover:text-white
            md:py-1.5 md:px-3 md:left-[18%] md:bottom-1
            lg:left-[25%] lg:bottom-1 lg:text-lg"
            >
              SEE MENU
            </a>
            <a
              href="https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us"
              target="_blank"
              className="absolute left-[20%] bottom-3 tracking-widest text-sm cursor-pointer font-primary lowercase bg-black text-white py-1 px-1.5 whitespace-nowrap transition-all hover:bg-th-red hover:text-white
            md:py-1.5 md:px-3 md:left-[52%] md:bottom-1
            lg:left-[50%] lg:bottom-1 lg:text-lg"
            >
              BOOK NOW
            </a>
          </div>
        </div>
      </div>
      <div
        className={`${
          open ? "fixed" : "hidden"
        } w-screen h-screen overflow-x-hidden overflow-y-scroll bg-black/70 z-[1000] block md:hidden`}
      >
        <div className="absolute w-5/6 max-w-[480px] h-auto md:max-w-[1200px] bg-transparent top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#EAE4DA]">
          <IoCloseSharp
            size={40}
            className="absolute -right-5 lg:-right-14 -top-12 text-white cursor-pointer"
            onClick={() => setOpen(false)}
          />
          <div className="relative block md:hidden py-5">
            <img
              src={popup_mobile}
              alt="popup_image"
              className="w-full h-full object-contain"
            />
            <a
              href="assets/menus/the_farmhouse_valentine_day.pdf"
              className="absolute left-[31%] bottom-20 tracking-widest text-sm cursor-pointer font-primary lowercase bg-black text-white py-2 px-5 whitespace-nowrap transition-all hover:bg-th-red hover:text-white
            md:py-1.5 md:px-3 md:left-[37%] md:bottom-4
            lg:left-[39%] lg:bottom-6 lg:text-lg"
            >
              SEE MENU
            </a>
            <a
              href="https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us"
              className="absolute left-[30%] bottom-7 tracking-widest text-sm cursor-pointer font-primary lowercase bg-black text-white py-2 px-5 whitespace-nowrap transition-all hover:bg-th-red hover:text-white
            md:py-1.5 md:px-3 md:left-[37%] md:bottom-4
            lg:left-[39%] lg:bottom-6 lg:text-lg"
            >
              BOOK NOW
            </a>
          </div>
          {/* <div className="relative block md:hidden">
            <img
              src={popup_mobile}
              alt="popup_image"
              className="w-full h-full object-contain"
            />
            <a
              href="/groupbookings"
              className="absolute left-[30%] bottom-7 tracking-widest text-sm cursor-pointer font-primary lowercase bg-black text-white py-2 px-5 whitespace-nowrap transition-all hover:bg-th-red hover:text-white
            md:py-1.5 md:px-3 md:left-[37%] md:bottom-4
            lg:left-[39%] lg:bottom-6 lg:text-lg"
            >
              INQUIRE NOW
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Popup;
